@import "../../variables.scss";

.outerContainer {
  position: relative;
  border-top: -33px;
  padding: 0 10px 6px 10px;
}

.innerContainer {
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
  background: $verification-widget-background-color;
  box-shadow: 0 1px 2px 0 rgba(87, 96, 111, 0.05), 0 5px 7px 0 rgba(87, 96, 111, 0.1);

  &__collapsed {
    position: relative;
    z-index: 100;
    margin-bottom: 3px;
  }

  &__expanded {
    position: relative;
    z-index: 100;
    margin-bottom: 3px;
  }
}

.head {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 2px 0 2px;
  background: linear-gradient(to top, #C3C5CB 0px, #dee0e4 7px);

  &:hover,
  &:focus {
    cursor: pointer;
    // background: darken($verification-widget-background-color, $hover-darken-percentage);
  }

  // &:active {
  //   background: darken($verification-widget-background-color, $active-darken-percentage);
  // }

  &_logoIcon {
    flex: 0 0 24px;
    align-items: flex-end;
    margin: 0 2px 0 10px;
  }

  &_text {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 5px 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &_shortText {
      display: none;
    }

    @media (max-width: 500px) {
      &_shortText {
        display: inline-block;
      }
      &_fullText {
        display: none;
      }
    }
  }

  &_textCarrot {
    display: flex;
    font-size: 1.2rem;
    font-weight: 600;
    color: $verification-widget-banner-link-color;

    &_carrotIcon {
      flex: 0 0 10px;
      margin-top: -8px;
      padding: 2px;
    }
  }
}

.headContent {
  display: flex;
  align-items: center;
  background: $verification-widget-background-color;
  margin: 9px 0 9px 0;

  &_text {
    flex: 0 1 auto;
    margin-right: 8%;
    margin-left: 5px;
    font-size: 1.2rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_container {
    display: flex;
    justify-content: flex-start;
    margin-left: 5px;
    align-items: baseline;
  }

  &_infoText {
    font-size: 1.2rem;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.5);
  }

  &_infoIcon {
    flex: 0 0 24px;
    vertical-align: bottom;
    margin: 2px 1px 0 5px;
  }

  &_infoDarkIcon {
    flex: 0 0 24px;
    vertical-align: bottom;
    margin: 2px 4px;
  }

  &_relativeTimeContainer {
    align-self: baseline;
    margin: 1px 5px 1px 5px;
    border-radius: 3px;
    color: $button-text-color;
    background: $verification-widget-relative-time-background-color;
    padding: 0 7px 2px 0;
  }
}

.gradient {
  position: absolute;
  z-index: 0;
  top: -40px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0004;
  background: linear-gradient(180deg, #0000 0px, #000f 40px);
  border-radius: 0 0 4px 4px;
}
