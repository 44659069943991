@import "../../variables.scss";

.container {
  background-color: $search-bar-background-color;
  box-shadow: 0 10px 10px 0 rgba(87, 96, 111, 0.05), 0 5px 7px 0 rgba(87, 96, 111, 0.1);

  @media screen and (max-width: $breakpoint-small) {
    display: none;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: $app-max-width;
  margin: auto;
  padding: 10px;
}

.searchForm {
  flex: 1 1 $search-box-min-width;
  display: flex;
  align-items: center;
  height: 40px;
  margin: 10px 10px 10px 0;
  padding: 0 10px;
  background-color: $search-box-background;
  box-shadow: 0 1px 2px 0 rgba(87, 96, 111, 0.05), 0 5px 7px 0 rgba(87, 96, 111, 0.1);
  border: 1px solid $search-box-border;
  border-radius: 4px;

  &_textBox {
    flex: 1;
    border: none;
    color: $content-text-color;
    outline: none;
    height: 38px;

    &::placeholder {
      color: $light-text-color;
    }
  }

  &_icon {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}

.tags {
  margin: 10px;
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.buttonTag {
  background-color: $verification-widget-banner-color;
  border: none;
  font-size: 1.2rem;
  font-weight: 500;
  color: $light-text-color;
  padding: 5px 10px;
  height: 100%;
  border: none;
  background-position: center left 5px, center left 5px;
  border-radius: 12px;
  margin-right: 0.5rem;

  &:hover {
    background-color: $light-text-color;
    color: $header-text-color;
    cursor: pointer;
  }
}

.showMoreTags {
  flex: 0 0 auto;
  color: $highlight-color;
  text-decoration: none;
  margin-left: 10px;
  font-size: 1.2rem;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: darken($highlight-color, $hover-darken-percentage-text);
    cursor: pointer;
  }

  &:active {
    text-decoration: underline;
    color: darken($highlight-color, $active-darken-percentage-text);
  }
}
