// Layout
$app-max-width: 900px;
$newsfeed-max-width: 585px;
$breakpoint-large: $app-max-width;
$breakpoint-small: $newsfeed-max-width;
$breakpoint-info-banner: 450px;
$header-height: 73px;
$arrow-height: 6px;
$search-box-min-width: 180px;

// Text colors
$text-color: #000;
$light-text-color: #57606f;
$lighter-text-color: #a3afbf;
$label-text-color: #a4b0be;
$content-text-color: #2f3542;

// Header colors
$header-text-color: #fff;
$header-background-color: #000;
$search-bar-background-color: #fff;
$header-notification-badge-color: #fe4d4d;

// Button colors (segmented control)
$button-text-color: #000;
$button-background-color: #f1f2f6;

// Verification widget colors
$verification-widget-background-color: #fff;
$verification-widget-banner-text-color: #848a94;
$verification-widget-banner-link-color: #3c6cff;
$verification-widget-banner-color: #eaecf5;
$verification-widget-title-background-color: #DEE0E4;
$verification-widget-relative-time-background-color: #F6C23F;

// Other colors
$placeholder-color: #dfe4ea;
$highlight-color: #4071ff;
$line-color: #ced6e088;
$post-box-background-color: #fff;
$post-box-border-color: #ced6e0;
$search-box-background: #fff;
$search-box-border: #ced6e0;
$create-add-link: #1a7ee0;

// Darkening & lightening elements when hovered / active
$hover-opacity: 0.85;
$hover-darken-percentage: 5%;
$hover-darken-percentage-text: 15%;
$active-darken-percentage: 10%;
$active-darken-percentage-text: 30%;
$hover-lighten-percentage: 5%;
$hover-lighten-percentage-text: 5%;
$active-lighten-percentage: 20%;
$active-lighten-percentage-text: 20%;
