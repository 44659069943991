$max-width: 860px;

.header {
  height: 87px;
  width: 100%;
  background-color: #000;
  color: #fff;

  &_content {
    display: flex;
    height: 100%;
    padding: 0 10px;
    align-items: center;
    max-width: $max-width;
    margin: auto;
  }

  &_logoText {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0.29rem;
    line-height: 1.9rem;
    margin-left: 15px;
  }
}

.main {
  display: flex;
  padding: 0 10px;
  max-width: $max-width;
  margin: auto;
  margin-top: 25px;
}

.advertisement {
  margin: 5px 0 0 40px;

  &_image {
    margin-top: 19px;
  }

  @media screen and (max-width: $max-width) {
    display: none;
  }
}
