@import "../../variables.scss";

.container {
  max-width: 100%;
}

.content {
  max-width: $newsfeed-max-width;
}

.item {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid $post-box-border-color;
  border-radius: 4px;
  background: $post-box-background-color;
  box-shadow: 0 1px 2px 0 rgba(87, 96, 111, 0.05), 0 5px 7px 0 rgba(87, 96, 111, 0.1);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: $breakpoint-small) {
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}
