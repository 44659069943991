@import "../../variables.scss";

.main {
  display: flex;
  margin: 40px auto 20px auto;
  max-width: $app-max-width;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-large) {
    justify-content: center;
  }

  @media screen and (max-width: $breakpoint-small) {
    margin-top: 20px;
  }
}
