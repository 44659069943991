@import "../../variables.scss";

.intro {
	display: block;
	color: $text-color;
	height: auto;
	max-width: $app-max-width;
    margin: 40px auto;
    
    @media screen and (max-width: $breakpoint-large) {
        margin: 40px 20px;
    }
    
}

