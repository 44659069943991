@import "../../variables.scss";

.container {
  position: relative;
  align-items: center;
  padding: 5px 10px;
  font-size: 1.2rem;
  letter-spacing: 0.06px;
  background: linear-gradient(#b0b4b9 0px, #dee0e4 7px);

  @media screen and (max-width: $breakpoint-info-banner) {
    flex-direction: column;
    align-items: flex-start;
  }

  &_wrap {
    font-weight: 600;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
  }
}

.authenticatedBy {
  color: $text-color;
  font-weight: 500;
  cursor: default;

  &_logo {
    flex: 0 0 24px;
    align-items: center;
    margin: 2px 13px 0 0;
  }

  &_infoIcon {
    vertical-align: text-bottom;
    margin: 0 0 0.15em 0.3em;
    width: 1em;
    height: 1em;
  }

  &:hover {
    color: darken($verification-widget-banner-text-color, $hover-darken-percentage-text);
  }

  &:hover &_infoIcon {
    circle {
      stroke: darken($verification-widget-banner-text-color, $hover-darken-percentage-text);
    }
    text {
      fill: darken($verification-widget-banner-text-color, $hover-darken-percentage-text);
    }
  }
}

.howItWorks {
  display: inline-block;
  color: $verification-widget-banner-link-color;
  text-align: left;

  &:hover,
  &:focus {
    color: darken($verification-widget-banner-link-color, $hover-darken-percentage-text);
  }

  &:active {
    color: darken($verification-widget-banner-link-color, $active-darken-percentage-text);
  }

  &_link {
    font-size: 1.2rem;
    padding-left: 31px;
    margin-left: 2px;
  }
}
