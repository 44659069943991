@import "../../variables.scss";

$inset-left: 45px;

.header {
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  &_avatar {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    border: 1px solid $line-color;
    border-radius: 50%;
  }

  &_title {
    flex: 1;
    color: $light-text-color;

    &_author {
      color: $content-text-color;
      font-weight: bold;
    }
  }

  &_timeAgo {
    color: $light-text-color;
    font-size: 1.1rem;
  }
}

.text {
  padding: 5px 0 10px $inset-left;
  color: $text-color;
  letter-spacing: 0.1px;
  line-height: 2.2rem;

  @media screen and (max-width: $breakpoint-small) {
    padding-left: 0;
  }
}

.photo {
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 $inset-left;

  &_image {
    max-width: 100%;
    background-color: $placeholder-color;
    user-select: none;
    border-radius: 5px;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding-left: 0;
  }
}

.articleInfo {
  padding: 20px 0 0 $inset-left;

  &_publisher {
    font-size: 1.1rem;
    letter-spacing: 0.33px;
    text-transform: uppercase;
  }

  &_title {
    margin-top: 3px;
    padding-bottom: 20px;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 2.2rem;
    color: $content-text-color;
    border-bottom: 1px solid $line-color;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding-left: 0;
  }
}

.footer {
  margin-left: $inset-left;

  &_actionMenu {
    display: flex;
    height: 45px;
    margin: 0;
    padding: 0;

    &_button {
      margin: 0 20px 0 5px;
      padding: 0;
      border: none;
      font-size: 1.4rem;
      font-weight: bold;
      letter-spacing: 0.09px;
      color: $content-text-color;
      background: none;

      &:first-child {
        margin-left: 0;
      }

      &:hover,
      &:focus {
        cursor: pointer;
        color: lighten($light-text-color, $hover-lighten-percentage-text);
      }

      &:active {
        color: lighten($light-text-color, $active-lighten-percentage-text);
      }
    }
  }

  @media screen and (max-width: $breakpoint-small) {
    margin-left: 0;
  }
}
