@import "../../variables.scss";

.container {
  width: 270px;
  padding: 10px;
  font-size: 1.4rem;
  line-height: 1.8rem;
  border: 1px solid $post-box-border-color;
  border-radius: 4px;
  background-color: $search-bar-background-color;
  box-shadow: 0 1px 2px 0 rgba(87, 96, 111, 0.05), 0 5px 7px 0 rgba(87, 96, 111, 0.1);

  @media screen and (max-width: $breakpoint-large) {
    display: none;
  }
}

.header {
  display: flex;
  margin: 10px 0 30px 0;
  justify-content: space-between;

  &_title {
    color: $text-color;
    font-weight: bold;
  }
}

.sponsorContent {
  &_title {
    margin: 10px 0 5px 0;
    padding: 0;
    color: $light-text-color;
    font-weight: bold;
  }

  &_text {
    margin: 10px 0 20px 0;
    color: $light-text-color;
  }

  &_image {
    width: 100%;
  }
}
