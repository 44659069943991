@import "../../variables.scss";

.section {
  padding: 10px;
  border-bottom: 1px solid $line-color;
  line-height: 1.8rem;
  letter-spacing: 0.07px;

  &:last-child {
    border-bottom: 1px solid #ced6e088;
  }
}

.captionWrap {
  display: flex;
  flex-direction: row;
}

.initialCaption {
  display: inline-block;
  flex-direction: row;
  align-items: center;

  &_logo {
    flex: 0 0 24px;
    align-items: flex-end;
    margin: 0 13px 0 0;
  }

  &_link {
    font-size: 1.2rem;
    padding-left: 33px;
  }

  &_locationLink {
    font-size: 1.2rem;
    font-family: IBMPlexSans;
  }

  &_text {
    flex: 0 1 auto;
    margin-right: 5%;
    font-size: 1.2rem;
    font-weight: 600;
  }

  &_creditsText {
    flex: 0 1 auto;
    padding-left: 47px;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.5);
  }

  &_highlightedText {
    color: $text-color;
    font-weight: 600;
  }

  &_container {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    padding-top: 5px;

    &_text {
      font-size: 1.2rem;
      color: rgba(0, 0, 0, 0.5);
      padding-bottom: 5px;
      padding-left: 37px;
    }

    &_infoText {
      font-weight: 400;
      font-size: 1.2rem;
      color: rgba(0, 0, 0, 0.5);
      padding-bottom: 5px;
    }
  }

  &_infoIcon {
    flex: 0 0 24px;
    vertical-align: bottom;
    margin: 2px 1px 2px 5px;
  }

  &_infoDarkIcon {
    flex: 0 0 24px;
    vertical-align: bottom;
    margin: 3px 4px;
  }

  &_relativeTimeContainer {
    align-self: baseline;
    margin: 1px 5px 1px 5px;
    border-radius: 3px;
    color: $button-text-color;
    background: $verification-widget-relative-time-background-color;
    padding: 0 7px 2px 0;
  }
}

.origin {
  &_coordinates {
    margin-left: -7px;
    margin-top: 5px;
  }
}
