@import "../../variables.scss";

.loading,
.error {
  text-align: center;
  color: $light-text-color;
}

.loading {
  font-size: 2rem;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
}

.retry {
  margin-top: 20px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 0;
  background-color: $highlight-color;
  color: #fff;

  &:hover {
    cursor: pointer;
    background-color: darken($highlight-color, $hover-darken-percentage);
  }
}
