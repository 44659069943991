@import "../../variables.scss";

.outerContainer {
  position: relative;
  padding: 5px 10px;
}

.innerContainer {
  position: relative;
  z-index: 20;
}

.line {
  position: absolute;
  border-left: 2px dotted $line-color;
  border-bottom: 2px dotted $line-color;
  z-index: 10;
}

.row {
  padding: 8px 0;
  font-size: 1.2rem;
}

.event {
  display: flex;

  &_logo {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex: 0 0 36px;
    height: 36px;

    img {
      width: 17px;
    }
  }

  &_titleDate {
    display: flex;
    flex: 1;
    margin: 0 10px;
    line-height: 1.5rem;

    @media screen and (max-width: $breakpoint-small) {
      align-items: left;
      flex-direction: column;
    }
  }

  &_title {
    flex: 1;
    color: $content-text-color;
  }

  &_dateTime {
    color: $lighter-text-color;

    @media screen and (max-width: $breakpoint-small) {
      margin-top: 1px;
    }
  }
}

.change {
  margin: 15px 10px 0 30px;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fffa;
  z-index: 100;
  pointer-events: none;
}
