@import "../../variables.scss";

.container {
  display: flex;
  align-items: center;
}

.avatar {
  width: 30px;
  height: 30px;
  background-color: $line-color;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 3px;
}

.textBox {
  flex: 1;
  margin: 0 15px;
  padding: 0;
  color: $content-text-color;
  font-size: 1.4rem;
  line-height: 1.8rem;
  border: none;
  outline: none;

  &::placeholder {
    color: $light-text-color;
  }
}

.imageIcon {
  flex: 0 0 auto;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
