$font-prefix: "../node_modules/@ibm/plex/";
@import "node_modules/@ibm/plex/scss/ibm-plex.scss";

html {
  font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  background: #f6f6f6;
  font-size: 1.4rem;
}
