@import "../../variables.scss";

.container {
  background-color: $header-background-color;
  padding: 0 10px;
  box-shadow: 0 1px 2px 0 rgba(87, 96, 111, 0.05), 0 5px 7px 0 rgba(87, 96, 111, 0.1);
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: $app-max-width;
  height: $header-height;
  margin: auto;
  color: $header-text-color;
}

.logo {
  display: flex;
  flex: 0 0 auto;

  &:hover {
    cursor: pointer;
    opacity: $hover-opacity;
  }
}

.hamburger {
  display: none;
  padding: 10px;

  &:hover {
    cursor: pointer;
    opacity: $hover-opacity;
  }

  @media screen and (max-width: $breakpoint-small) {
    display: block;
  }
}

.menu {
  display: flex;

  &_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
    margin-left: 30px;

    &:hover {
      cursor: pointer;
      opacity: $hover-opacity;
    }

    &_icon {
      height: 20px;
      width: 19px;
      padding: 0.6rem;
    }

    &_notificationBadge {
      display: inline-block;
      height: 9px;
      width: 9px;
      margin: 0 0 5px 4px;
      background: $header-notification-badge-color;
      border-radius: 50%;
    }
  }

  @media screen and (max-width: $breakpoint-small) {
    display: none;
  }
}
