@import "../../variables.scss";

.title {
  margin: 0 0 20px 0;
  font-size: 2.6rem;
}

.authorDate {
  display: flex;
  justify-content: space-between;
  opacity: 0.5;
  font-size: 1.2rem;
}

.photo {
  margin: 10px 0;

  // img {
  //   width: 100%;
  // }

  &_image {
    max-width: 100%;
    background-color: $placeholder-color;
    user-select: none;
    border-radius: 5px;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding-left: 0;
  }

  &_info {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 10px 0;
    border-bottom: 1px solid #0004;
  }

  &_caption {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  &_source {
    opacity: 0.5;
    font-size: 1rem;
  }
}

.paragraph {
  margin: 10px 0;
  font-size: 1.4rem;
  line-height: 2.3rem;
}
