@import "../../variables.scss";

.link {
  color: $highlight-color;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: darken($highlight-color, $hover-darken-percentage-text);
  }

  &:active {
    text-decoration: underline;
    color: darken($highlight-color, $active-darken-percentage-text);
  }
}
