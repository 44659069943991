@import "../../variables.scss";

.outerContainer {
  position: relative;
  margin: 0 5px 0 46px;
  padding-bottom: 12px;

  &_title {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 10px 0;
  }

  &_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &_text {
      font-size: 1.2rem;
      max-width: fit-content;
      margin-top: 4px;
      color: rgba(0, 0, 0, 0.5);
    }

    &_imageContainer {
      width: 122px;
      align-content: space-between;
      margin-bottom: 5px;
      margin-right: 15px;

      &_image {
        border-radius: 2px;
        position: relative;
        height: 80px;
        background-size: cover;

        &_link {
          position: absolute;
          width: 100%;
          height: 18%;
          bottom: 0;
          font-size: 0.9rem;
          color: $header-text-color;
          background: linear-gradient(to bottom, #0000, #000000);
          border-radius: 2px;
        }
      }

      &_linkIcon {
        margin: -0.3rem 0.6em;
        width: 1.2em;
        height: 1.3em;
      }
    }
  }
}
