$max-width: 860px;

.header {
  height: 100px;
  width: 100%;
  border-top: 10px solid #57a200;

  &_content {
    display: flex;
    height: 100%;
    padding: 0 10px;
    align-items: center;
    max-width: $max-width;
    margin: auto;
  }

  &_logoText {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.9rem;
    margin-left: 20px;
  }
}

.main {
  display: flex;
  padding: 0 10px;
  max-width: $max-width;
  margin: auto;
}

.articleContainer {
  border-top: 2px solid #57a200;
  padding-top: 20px;
}

.advertisement {
  margin: 0 0 0 20px;
  border-top: 2px solid #57a200;

  &_image {
    margin-top: 19px;
  }

  @media screen and (max-width: $max-width) {
    display: none;
  }
}
