@import "../../variables.scss";

.container {
  display: flex;
  align-items: flex-start;
}

.photoCaption {
  color: $content-text-color;
  font-style: italic;

  &__highlighted {
    padding: 2px 5px;
    border-radius: 7.5px;
    background-color: rgba(64, 113, 255, 0.2);
    color: #2f3542;
  }
}

.pencilIcon {
  position: relative;
  display: flex;
  width: 9px;
  height: 9px;
  margin-left: -5px;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  background: $highlight-color;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
}

.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &_photoCaption {
    max-width: 160px;
    margin-top: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;

    // Disables the native tooltip in Safari
    // See: https://stackoverflow.com/questions/20974276/prevent-safari-from-showing-tooltip-when-text-overflow-is-hidden-with-ellipsis
    &::before {
      content: "";
      display: block;
    }
  }
}

// The popup has 5px invisible padding at the bottom
// so that users can move their mouse over the popup without having it disappear
.popup {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 25px;
  padding-bottom: 5px;
  z-index: 1000;

  &_inner {
    table-layout: fixed;
    min-width: 100%;
    max-width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.23);

    td {
      width: 50%;
    }
  }

  &_title {
    padding-bottom: 10px;
    color: $light-text-color;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  &_organization {
    padding-top: 10px;
    color: $lighter-text-color;
    font-size: 1.1rem;
  }
}
