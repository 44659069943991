@import "../../variables.scss";

.tabBar {
  display: flex;
  padding-left: 46px;
  border-bottom: 1px solid $line-color;

  &_item {
    margin-right: 15px;
    padding-top: 10px;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 10px;
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: $light-text-color;
    outline: none;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    // &:active {
    //   background-color: darken($button-background-color, $active-darken-percentage);
    // }

    &__selected {
      display: inline-block;
      border-bottom: 2px solid $highlight-color;
      color: $highlight-color;
      padding-top: 12px;
      background: white;

      &:hover,
      &:active,
      &:focus {
        cursor: default;
      }
    }
  }
}

.warning {
  padding: 8px;
  align-items: center;
  border-bottom: 1px solid $line-color;

  &_wrap {
    display: flex;
    margin-left: -10px;
  }

  &_innerBorder {
    padding: 8px;
    border: 1px solid $verification-widget-relative-time-background-color;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: rgba(246, 194, 63, 0.3);
  }

  &_logoIcon {
    flex: 0 0 24px;
    align-items: flex-end;
    margin: 0 2px -10px 10px;
  }

  &_text {
    position: relative;
    flex: 0 1 auto;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 5px;
    align-self: flex-end;
  }

  &_closeIcon {
    align-items: flex-end;
    margin: 8px 0 0 10px;
  }
}

.howItWorks {
  text-align: left;

  &:hover,
  &:focus {
    color: darken($verification-widget-banner-link-color, $hover-darken-percentage-text);
  }

  &:active {
    color: darken($verification-widget-banner-link-color, $active-darken-percentage-text);
  }

  &_link {
    font-size: 1.2rem;
    padding-left: 31px;
    margin-left: 1px;
  }
}
